<template>
    <div class="relative">
        <Loading v-if="enableOverlay" :active="dataLoading || showError"
            :is-full-page="false" color="springGreen" backgroundColor="culturedGray" :opacity="0.75">
            <DataNotLoaded v-if="showError" />
        </Loading>
        <slot v-if="!renderAfterLoad || !dataLoading"></slot>
    </div>
</template>

<script>
import DataNotLoaded from "@/components/shared/DataNotLoaded";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {traceError} from "@/tracing";


export default {
    name: "PromiseContainer",
    components: {
        DataNotLoaded,
        Loading
    },
    model: {
        prop: 'promises',
        event: 'update:promises'
    },
    props: {
        promises: [],
        hideOnNonInitialLoads: {
            type: Boolean,
            default: false
        },
        showOverlay: {
            type: Boolean,
            default: true
        },
        renderAfterLoad: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dataLoading: true,
            dataFetchError: false,
            loadingPromises: [],
            firstLoad: true,
            showError: this.showDataFetchError(),
            destroyed: false,
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.dataLoading = true
            this.dataFetchError = false
            this.loadingPromises = [...this.promises]
            this.$emit('update:promises', [])
            this.$store.commit("addRemainingPromises", this.loadingPromises.length)
            this.showError = this.showDataFetchError()
            Promise.all(this.loadingPromises)
                .catch((err, vm, info) => {this.dataFetchError = true
                traceError(err, info)
                })
                .finally(() => {
                    if (!this.destroyed) {
                        this.$store.commit("removeRemainingPromises", this.loadingPromises.length)
                        if (this.promises.length > 0) {
                            this.loadData()
                        } else {
                            this.loadingPromises = []
                            this.dataLoading = false
                            this.showError = this.showDataFetchError()
                            if (this.firstLoad) {
                                this.firstLoad = false
                            }
                        }
                    }
                })
        },
        showDataFetchError() {
            return this.dataFetchError && this.firstLoad
        }
    },
    destroyed() {
        this.destroyed = true
        this.$store.commit("removeRemainingPromises", this.loadingPromises.length)
    },
    computed: {
        firstParentPromiseContainer() {
            let parent = this.$parent;

            while (parent && parent.$options._componentTag !== this.$options.name) {
                parent = parent.$parent;
            }
            return parent;
        },
        isParentPromiseContainerLoading() {
            if (!this.firstParentPromiseContainer || this.firstParentPromiseContainer.dataLoading == undefined) {
                return false
            } 
            
            return this.firstParentPromiseContainer.enableOverlay
        },
        enableOverlay() {
            return this.showOverlay && !this.isParentPromiseContainerLoading
        }
    },
    watch: {
        promises: function (newValue) {
            if (newValue.length > 0 && !this.dataLoading) {
                this.loadData()
            }
        }
    }
}
</script>