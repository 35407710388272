<template>
  <div>
    <v-icon class="vertical-align-baseline alert" dense>mdi-alert</v-icon>
    <span class="ml-1 black--text">Data kon niet geladen worden. Probeer later nogmaals of contacteer
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs"
              v-on="on" class="clickable" @click="copyMailToClipBoard" @mouseout="reset">{{ infoMail }}</span>
      </template>
      <span>{{ copyText }}</span>
    </v-tooltip>
    </span>
  </div>
</template>

<script>

import GeneralConstants from "@/shared/constants/GeneralConstants";

export default {
  name: "DataNotLoaded",
  data() {
    return {
      copyText: "Klik om te kopiëren",
      infoMail: GeneralConstants.INFO_MAIL
    }
  },
  methods: {
    copyMailToClipBoard() {
      navigator.clipboard.writeText(this.infoMail)
      this.copyText = "gekopieerd"
    },
    reset() {
      this.copyText = 'Klik om te kopiëren'
    }
  }
}
</script>
